import React, {Component} from 'react';
import './App.css';



  const list = [
    {
      id: 1,
      name: 'Feature 1',
      description: 'Automatically draw process maps for features and user stories',
      votes: 0
    },
    {
      id: 2,
      name: 'Feature 2',
      description: 'Integration to Trello, JIRA, Asana, Notion etc',
      votes: 0
    },
    {
      id: 3,
      name: 'Feature 3',
      description: 'Add teams that will be involved - UX, UI, Code, Design, Database, Process, Operations etc',
      votes: 0
    },
    {
      id: 4,
      name: 'Feature 4',
      description: 'Automated Sprint planning - Allow AI to plan delivery based on team size and work to be done ',
      votes: 0
    },
    {
      id: 5,
      name: 'Feature 5',
      description: 'Product Manager of the day and the launched product',
      votes: 0
    },
    {
      id: 6,
      name: 'Feature 6',
      description: 'Creator portal. Get paid when your project is downloaded by an Enterprise client',
      votes: 0
    },
    {
      id: 7,
      name: 'Feature 7',
      description: 'Colourful post-it like stories that can be printed ',
      votes: 0
    },
    {
      id: 8,
      name: 'Feature 8',
      description: 'Product news on Dashboard',
      votes: 0
    }
    ,
    {
      id: 9,
      name: 'Feature 9',
      description: 'Public profile that everyone can see',
      votes: 0
    },
    {
      id: 10,
      name: 'Feature 10',
      description: 'Google chrome extention',
      votes: 0
    }
  ]


  class App extends Component {

    state = {
      members: []
    }

    componentDidMount(){
      this.setState({ members: list })
    }

    handleClick = memberId => {
      const updatedList = this.state.members.map(member =>{
        if(member.id === memberId) {
          return Object.assign({}, member, {
            votes: member.votes + 1
          })
        } else {
          return member
        }
      })
      this.setState({
        members: updatedList 
      })
    }

    render() {
      return(
        this.state.members.map(member =>
          <Beatle key={member.id} name={member.name} description={member.description} id={member.id} votes={member.votes} onVote={this.handleClick} />
        )
        
      )
    }
  }

  class Beatle extends Component {

    onClickHandle = () => this.props.onVote(this.props.id)
    
    render() {
      return (
        <div className='App'>
          {this.props.name} <br></br>{this.props.description} <button onClick={this.onClickHandle}>+</button>
          {this.props.votes}
        </div>
      )
    }
  }

export default App
